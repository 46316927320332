const exportJobTemplate = {
  taskId: null,
  url: null,
  status: 'IDLE',
  message: null,
};

export default {
  exportJobs: [
    // RO Export
    { name: 'Receive Order List', ...exportJobTemplate },

    // Receive Export
    { name: 'Receive List', ...exportJobTemplate },

    // Inventory Report Export
    { name: 'Inventory REPORT', ...exportJobTemplate },

    // Return Export
    { name: 'Return Receive List', ...exportJobTemplate },

    // Inventory List Export
    { name: 'Inventory List', ...exportJobTemplate },

    // Inventory List download
    { name: 'Inventory List Products', ...exportJobTemplate },

    // Inventory List download
    { name: 'Inventory Master Products', ...exportJobTemplate },

    // Transaction List Download
    { name: 'Transaction List Products', ...exportJobTemplate },

    //  Download discrepancy report
    { name: 'Discrepancy Report', ...exportJobTemplate },

    //  Download Error report
    { name: 'Download Error Report', ...exportJobTemplate },

    //  Download Mapping Products report
    { name: 'Inventory Mapping Products', ...exportJobTemplate },

    //  Download Master Product Image
    { name: 'Master Product Image', ...exportJobTemplate },

    //  Download Shopify Master Product Image
    { name: 'Shopify Master Product Image', ...exportJobTemplate },

    //  Download Walmart Master Product Image
    { name: 'Walmart Master Product Image', ...exportJobTemplate },

    //  Download Amazon  Master Product Image
    { name: 'Master Amazon Product Image', ...exportJobTemplate },

    //  Download Shipment Transaction
    { name: 'Shipment Transaction', ...exportJobTemplate },

    //  Download Shipment Transaction
    { name: 'Invoice Report', ...exportJobTemplate },

    //  Download Product Cost
    { name: 'Product Cost', ...exportJobTemplate },
    //  Download Packing List
    { name: 'Packing List', ...exportJobTemplate },
    //  Download  Shipments Packing”
    { name: 'Shipments Packing', ...exportJobTemplate },
    //  Download  Shipments”
    { name: 'Shipments', ...exportJobTemplate },
    //  Download Filtered Shipments”
    { name: 'Filtered Shipments', ...exportJobTemplate },
  ],
};
