import api from '@/api';
import { downloadURI } from '@/helper';

export default {
  /**
   *
   * @param {Object} vuex - vuex store instance
   * @param {Function} vuex.commit - vuex commit function
   * @param {Function} vuex.dispatch - vuex dispatch function
   * @param {Object} obj - export request parameters
   * @param {Number} obj.templateType - export request template type
   * @param {Object} obj.filterData - export request filters
   */
  createExportRequest({ commit, dispatch }, { templateType, filterData }) {
    commit('SET_STATUS', { templateType, status: 'PENDING' });

    api.bearer
      .post(
        { templateType, filterData },
        'supply-chain/3pl/export-template-excel'
      )
      .then((response) => {
        const { ApiStatus, Data } = response.data;

        if (!ApiStatus)
          throw new Error(
            'Something went wrong while obtaining export task. Please try again later...'
          );

        commit('SET_TASK', { templateType, taskId: Data.UUID });
        dispatch('checkExportTaskStatus', { templateType, taskId: Data.UUID });
      })
      .catch((_error) => {
        commit('SET_STATUS', {
          status: 'ERROR',
          message:
            'Something went wrong while obtaining export request. Please try again later....',
        });
      });
  },
  checkExportTaskStatus({ commit, dispatch }, { templateType, taskId }) {
    commit('SET_STATUS', { templateType, status: 'PENDING' });

    api.bearer
      .post({ templateType, taskId }, 'supply-chain/3pl/template-task-status')
      .then((response) => {
        const { ApiStatus, ApiStatusMessage, Data } = response.data;

        if (!ApiStatus)
          throw new Error(
            'Something went wrong while checking export status. Please try again later...'
          );

        switch (Data.status) {
          case 0:
          case 1:
          case 4:
            setTimeout(() => {
              dispatch('checkExportTaskStatus', { templateType, taskId });
            }, 5000);

            return;

          case 2:
            commit('SET_URL', { templateType, url: Data.downloadFileName });
            commit('SET_STATUS', {
              templateType,
              status: 'SUCCESS',
              message: 'Requested file has been downloaded.',
            });
            if (templateType !== 11) {
              downloadURI(Data.downloadFileName, 'Requested file - 3PL.xlsx');
              dispatch('clearExportJob', templateType);
            }

            return;
          case 3:
            commit('SET_STATUS', {
              templateType,
              status: 'ERROR',
              message:
                'Something went wrong while obtaining export request. Please try again later....',
            });

          default:
            throw new Error(ApiStatusMessage);
        }
      })
      .catch((_error) => {
        const message =
          'Something went wrong while obtaining your export file. Please try again later...';
        commit('SET_STATUS', templateType, { status: 'ERROR', message });
      });
  },
  setStatus({ commit }, { templateType, status, message }) {
    commit('SET_STATUS', { templateType, status, message });
  },
  setTaskId({ commit }, { templateType, taskId }) {
    commit('SET_TASK', { templateType, taskId });
  },
  clearExportJob({ commit }, templateType) {
    commit('CLEAR_TASK', templateType);
  },
  clearExportJobs([commit]) {
    commit('CLEAR_TASKS');
  },
};
